@tailwind base;
@tailwind utilities;
@tailwind components;

@import "assets/styles/swiper.scss";

@font-face {
  font-family: "Aria";
  src: url("./assets/fonts/ABCMarfa-Regular.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "AriaLight";
  src: url("./assets/fonts/ABCMarfa-Light.otf") format("opentype");
  font-weight: 100 !important;
  font-style: normal;
}

@font-face {
  font-family: "AriaBold";
  src: url("./assets/fonts/ABCMarfa-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AriaItalic";
  src: url("./assets/fonts/ABCMarfa-RegularItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AriaLightItalic";
  src: url("./assets/fonts/ABCMarfa-LightItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --white: #fff;
  --black: #000;
  --gray-50: #f9f9f9;
  --gray-100: #ececec;
  --gray-200: #cdcdcd;
  --gray-300: #b4b4b4;
  --gray-400: #9b9b9b;
  --gray-500: #676767;
  --gray-600: #424242;
  --gray-700: #2f2f2f;
  --gray-800: #212121;
  --gray-900: #171717;
  --gray-950: #0d0d0d;
  --scroll-bar-bg: var(--gray-100);
  --scroll-bar-thumb-bg: var(--gray-200);

  --border-light: var(--gray-200);
  --border-medium: var(--gray-50);

  --main-surface-primary: var(--white);
  --main-surface-secondary: var(--gray-50);
  --main-surface-tertiary: var(--gray-100);

  --text-primary: var(--black);
  --text-secondary: var(--gray-100);
  --text-tertiary: var(--gray-200);

  --sidebar-surface-primary: var(--gray-800);
  --sidebar-surface-secondary: var(--gray-600);

  --sidebar-text-primary: var(--gray-200);

  --error-text-primary: rgb(220 38 38);

  //onboarding palette
  --onboarding-bg-primary: black;
  --onboarding-bg-secondary: #333333;
  --onboarding-bg-tertiary: #f1f1f1;

  --onboarding-text-primary: black;
  --onboarding-text-secondary: white;
  --onboarding-text-tertiary: #8b8b8b;
  --onboarding-text-error: #fd4643;

  --onboarding-input-bg: #f1f1f1;
  --onboarding-input-error-bg: #fd464320;

  --icon-color: black;

  --swiper-fade-gradient: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff);

  --text-disabled: #c8c8c8;

  background-color: white;
}

.icon-color-current {
  fill: var(--icon-color);
}

.filter-white {
  filter: invert(100%) sepia(100%) saturate(18%) hue-rotate(329deg) brightness(106%) contrast(105%);
}

.filter-black {
  filter: invert(0%) sepia(0%) saturate(7495%) hue-rotate(167deg) brightness(98%) contrast(100%);
}

.dark {
  --text-primary: var(--gray-200);
  --text-secondary: var(--gray-300);
  --text-tertiary: var(--gray-400);
  --text-quaternary: var(--gray-500);
  --border-light: hsla(0, 0%, 100%, 0.1);
  --border-medium: hsla(0, 0%, 100%, 0.15);
  --border-heavy: hsla(0, 0%, 100%, 0.2);
  --border-xheavy: hsla(0, 0%, 100%, 0.25);
  --main-surface-primary: var(--gray-800);
  --main-surface-secondary: var(--gray-700);
  --main-surface-tertiary: var(--gray-600);
  --sidebar-surface-primary: var(--gray-900);
  --sidebar-surface-secondary: var(--gray-800);
  --sidebar-surface-tertiary: var(--gray-700);
  --link: #7ab7ff;
  --link-hover: #5e83b3;

  --scroll-bar-bg: #212121;
  --scroll-bar-thumb-bg: #313131;

  --icon-color: var(--white);

  color-scheme: dark;

  --swiper-fade-gradient: linear-gradient(90deg, hsla(0, 0%, 100%, 0), var(--main-surface-primary));
}

.onboarding {
  &-bg-primary {
    background-color: var(--onboarding-bg-primary);
  }
  &-bg-secondary {
    background-color: var(--onboarding-bg-secondary);
  }
  &-bg-tertiary {
    background-color: var(--onboarding-bg-tertiary);
  }

  &-text-primary {
    color: var(--onboarding-text-primary);
  }

  &-text-secondary {
    color: var(--onboarding-text-secondary);
  }

  &-text-tertiary {
    color: var(--onboarding-text-tertiary);
  }

  &-text-error {
    color: var(--onboarding-text-error);
  }

  &-input {
    background-color: var(--onboarding-input-bg);
  }

  &-input-error {
    background-color: var(--onboarding-input-error-bg);
  }
}

.pricing {
  &-text-primary {
    color: var(--onboarding-text-primary);
  }

  &-text-secondary {
    color: var(--onboarding-text-secondary);
  }

  &-text-tertiary {
    color: var(--onboarding-text-tertiary);
  }
}

.prose {
  font-size: 14px !important;
  line-height: 22.5px !important;
  color: var(--text-primary);
  max-width: 100%;

  &.error {
    color: var(--error-text-primary) !important;
  }

  strong {
    font-weight: 400;
    font-size: 15px;
    font-family: "AriaBold";
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "AriaBold";
    color: var(--text-primary) !important;
  }

  em,
  i {
    font-family: "AriaItalic";
  }
}

::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-thumb-bg);
  border-radius: 20px;
}

::-webkit-scrollbar {
  width: 10px;
}

.bg-token-border-light {
  background-color: rgba(0, 0, 0, 0.1);
  background-color: var(--border-light);
}

.bg-token-border-dark {
  background-color: rgba(0, 0, 0, 0.1);
  background-color: #313131;
}

.bg-token-border-medium {
  background-color: rgba(0, 0, 0, 0.15);
  background-color: var(--border-medium);
}

.bg-token-main-surface-primary {
  background-color: #fff;
  background-color: var(--main-surface-primary);
}

.bg-token-main-surface-secondary {
  background-color: #ececec;
  background-color: var(--main-surface-secondary);
}

.bg-token-main-surface-tertiary {
  background-color: #e3e3e3;
  background-color: var(--main-surface-tertiary);
}

.bg-token-sidebar-surface-primary {
  background-color: #f9f9f9;
  background-color: var(--sidebar-surface-primary);
}

.bg-token-sidebar-surface-secondary {
  background-color: #ececec;
  background-color: var(--sidebar-surface-secondary);
}

.bg-token-text-primary {
  background-color: #0d0d0d;
  background-color: var(--text-primary);
}

.bg-token-text-tertiary {
  background-color: #9b9b9b;
  background-color: var(--text-tertiary);
}

.text-token-main-surface-primary {
  color: #fff !important;
  color: var(--main-surface-primary) !important;
}

.text-token-text-primary {
  color: #0d0d0d !important;
  color: var(--text-primary) !important;
}

.bg-transparent {
  background-color: transparent;
}

.transition-width {
  transition-duration: 0.15s;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-neutral {
  background-color: #fff;
  background-color: var(--main-surface-primary);
  border-color: rgba(0, 0, 0, 0.15);
  border-color: var(--border-medium);
  border-width: 1px;
  color: #0d0d0d;
  color: var(--text-primary);
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.btn-neutral:hover {
  background-color: #ececec;
  background-color: var(--main-surface-secondary);
}

.btn-neutral:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-offset-width: 2px;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent);
}

.btn-neutral.focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 103, 103, var(--tw-ring-opacity));
}

.btn-neutral:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 103, 103, var(--tw-ring-opacity));
}

.border-token-border-heavy {
  border-color: rgba(0, 0, 0, 0.2);
  border-color: var(--border-heavy);
}

.hover\:bg-token-sidebar-surface-secondary:hover {
  background-color: #ececec;
  background-color: var(--sidebar-surface-secondary);
}

.dark .dark\:border-token-border-heavy {
  border-color: rgba(0, 0, 0, 0.2);
  border-color: var(--border-heavy);
}

textarea:focus-visible {
  outline: 2px solid black;
  border-radius: 3px;
}

.dark .dark\:text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

textarea:focus,
input:focus {
  outline: none;
}

.btn {
  width: 30px;
  height: 30px;

  &--apple {
    background: url("assets/icons/providers/apple.svg") no-repeat center;
    background-size: 25px 25px;
  }

  &--google {
    background: url("assets/icons/providers/google.svg") no-repeat center;
    background-size: 25px 25px;
  }

  &--institution {
    background: url("assets/icons/providers/institution.svg") no-repeat center;
    background-size: 25px 25px;
  }

  &--facebook {
    background: url("assets/icons/providers/facebook.svg") no-repeat center;
    background-size: 25px 25px;
  }

  &--weibo {
    background: url("assets/icons/providers/weibo.svg") no-repeat center;
    background-size: 25px 25px;
  }

  &--wechat {
    background: url("assets/icons/providers/wechat.svg") no-repeat center;
    background-size: 25px 25px;
  }
}

.icon--arrow-right {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url("/assets/icons/btns/arrow-right.svg") no-repeat center;
}

.icon--cross {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url("/assets/icons/btns/cross.svg") no-repeat center;
}

.hover\:transition-500:hover {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 550ms;
}

.hover\:scale-120:hover {
  --tw-scale-x: 1.2;
  --tw-scale-y: 1.2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

@keyframes enter-going-down {
  from {
    transform: translate(-50%, -60%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}

@keyframes enter-scaling {
  from {
    transform: scale(0.8) translate(-50%, -50%);
    transform-origin: left;
  }
  to {
    transform: scale(1) translate(-50%, -50%);
    transform-origin: left;
  }
}

@keyframes enter-scale-down {
  from {
    transform: scale(1.5) translate(-50%, -60%);
    transform-origin: left;
  }
  to {
    transform: scale(1) translate(-50%, -50%);
    transform-origin: left;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes bounce-in {
  0% {
    transform: translate(-50%, -85%);
  }
  18% {
    transform: translate(-50%, -50%);
  }
  60% {
    transform: translate(-50%, -65%);
  }
  80% {
    transform: translate(-50%, -50%);
  }
  90% {
    transform: translate(-50%, -53%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes scale-rotate {
  30% {
    transform: scale(1.05) translate(-50%, -50%);
    transform-origin: left;
  }
  40%,
  60% {
    transform: rotate(-3deg) scale(1.05) translate(-50%, -50%);
    transform-origin: left;
  }
  50% {
    transform: rotate(3deg) scale(1.05) translate(-50%, -50%);
    transform-origin: left;
  }
  70% {
    transform: rotate(0deg) scale(1.05) translate(-50%, -50%);
    transform-origin: left;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
    transform-origin: left;
  }
}

.shake-error {
  animation: shake 0.2s ease-in-out 0s 2 !important;
}

.text-primary {
  color: var(--text-primary);
}

.text-secondary {
  color: var(--text-secondary);
}

.text-tertiary {
  color: var(--text-tertiary);
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}

.aria-group .aria-group-item {
  opacity: 0 !important;
}

.aria-group:hover .aria-group-item {
  opacity: 1 !important;
}

.aria-group .aria-group-item:last-child {
  opacity: 1 !important;
}
