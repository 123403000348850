swiper-container::part(wrapper) {
  @media (max-width: 768px) {
    overflow: auto !important;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

swiper-container::part(container) {
  max-width: 795px;
}
